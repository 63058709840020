/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import * as React from "react";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import axios from "axios";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Select, // Added Select component
  MenuItem, // Added MenuItem component
  FormControl, // Added FormControl component
  InputLabel, // Added InputLabel component
  useTheme,
  Grid,
} from "@mui/material";
import { tokens } from "../../theme";
import Swal from "sweetalert2";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import DeleteIcon from "@mui/icons-material/Delete";
import Autocomplete from "@mui/lab/Autocomplete";
import "../../index.css";
import { useNavigate } from "react-router-dom";
import { editOptionAlert } from "../support/sweetAlert";
import { handleOrdersDelete } from "../support/deleteData";
import { fetchCustomerOptionData, options } from "../support/customerData";
import AddIcon from "@mui/icons-material/Add";
import { insertedBy, isReviewed } from "../support/sessionUserName";
import { baseURL } from "../support/baseURL";

export default function OrdersTable() {
  const [rows, setRows] = useState([]);
  const [searchValue, setSearchValue] = useState(null); // State variable for search value
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [setOpenModal] = useState(false);
  const navigate = useNavigate();

  const [bulkOrders, setBulkOrders] = useState([]);

  const [editOpen, setEditOpen] = useState(false);
  const [editedRow, setEditedRow] = useState(null);
  const [editedDescription, setEditedDescription] = useState("");
  const [editedQuantity, setEditedQuantity] = useState("");
  const [editedWages, setEditedWages] = useState("");
  const [editedWeight, setEditedWeight] = useState("");
  const [editedOrderDate, setEditedOrderDate] = useState("");
  const [editedStatus, setEditedStatus] = useState("");
  const [editedOrderStatus, setEditedOrderStatus] = useState(null);
  const [errorWeight, setErrorWeight] = useState("");
  const [customerOptions, setCustomerOptions] = useState([]);
  const [error, setError] = useState("");
  const [statusError, setErrorStatus] = useState("");

  const [statusDialogOpen, setStatusDialogOpen] = useState(false);
  const [orderStatusDialogOpen, setOrderStatusDialogOpen] = useState(false);

  const [otherDescription, setOtherDescription] = useState("");
  const [showOtherTextField, setShowOtherTextField] = useState(false);

  const handleNavigateOverview = () => {
    navigate("/overView");
  };

  const handleStatusClick = (row) => {
    if (sessionStorage.getItem("userType") === "1") {
      setEditedRow(row);
      setEditedStatus(row.is_reviewed === 1 ? "Approved" : "Pending");
      setStatusDialogOpen(true);
    } else {
      alert("Access restricted!");
    }
  };

  const handleDescriptionChange = (e) => {
    const value = e.target.value;
    if (value === "other") {
      setShowOtherTextField(true);
      // setNewCustomer({ ...newCustomer, description: otherDescription });
    } else {
      setShowOtherTextField(false);
      setNewCustomer({ ...newCustomer, description: value });
    }
  };

  const handleOrderStatusClick = (row) => {
    if (sessionStorage.getItem("userType") === "1") {
      if (row.is_reviewed === 1) {
        setEditedRow(row);
        setEditedOrderStatus(row.order_status === 2 ? "Closed" : "Pending");
        setOrderStatusDialogOpen(true);
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Please Change Is Reviewed Status To Approved",
        });
      }
    } else {
      if (row.is_reviewed === 1) {
        setEditedRow(row);
        // eslint-disable-next-line default-case
        switch (row.order_status) {
          case 0:
            setEditedOrderStatus("Pending");
            break;
          case 1:
            setEditedOrderStatus("InProgress");
            break;
          case 2:
            setEditedOrderStatus("Closed");

            break;
        }
        setOrderStatusDialogOpen(true);
      } else {
        //  alert("Please Change the status of the review first.")
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Please Change Is Reviewed Status To Approved",
        });
      }
    }
  };

  const handleStatusUpdate = async () => {
    try {
      if (editedStatus === "Pending" || editedStatus === "Approved") {
        setErrorStatus("Please Select The Status");
        return;
      }
      // Construct the payload with order_id and editedStatus
      const payload = {
        orderId: editedRow.order_id,
        //is_reviewed: editedStatus == "Approved" ? 1 : 0, // Assuming 1 for Approved and 0 for Pending
        is_reviewed: editedStatus === 1 ? 1 : 0,
      };

      // Make a POST request to the API endpoint
      const response = await axios.post(
        `${baseURL}/orders/updateStatus`,
        payload
      );

      // Handle the response
      if (response.data.mStatus === 200) {
        alert("Status updated successfully");
        fetchOrdersData(); // Refresh data if needed
      }

      if (response.data.mStatus === 402) {
        alert("Error While Updating Status Of Order");
      }
      setErrorStatus("");
      // Close the status dialog
      setStatusDialogOpen(false);
    } catch (error) {
      console.error("Error updating status:", error);
    }
  };

  const handleOrderStatus = async () => {
    try {
      if (
        editedOrderStatus === "Pending" ||
        editedOrderStatus === "Closed" ||
        editedOrderStatus === "InProgress"
      ) {
        setError("Please select the order status");
        return;
      }
      // Construct the payload with order_id and editedStatus
      const payload = {
        orderId: editedRow.order_id,
        //order_status: editedOrderStatus === 1 ? 1 : 0,
        order_status: editedOrderStatus,
      };

      // Make a POST request to the API endpoint
      const response = await axios.post(
        `${baseURL}/orders/updateOrderStatus`,
        payload
      );

      // Handle the response
      if (response.data.mStatus === 200) {
        alert("Order Status updated successfully");
        fetchOrdersData(); // Refresh data if needed
      }

      if (response.data.mStatus === 402) {
        alert("Error While Updating Status Of Order");
      }

      // Close the status dialog
      setOrderStatusDialogOpen(false);
    } catch (error) {
      console.error("Error updating status:", error);
    }
  };

  const [newCustomer, setNewCustomer] = useState({
    description: "",
    quantity: "",
    wages: "",
    weight: "",
    customer_id: "",
    inserted_by: insertedBy(),
    order_status: 0,
    is_reviewed: isReviewed(),
    order_date: new Date().toISOString().split("T")[0], // Initialize with today's date
  });


  const handleEditOpen = (row) => {
    if (sessionStorage.getItem("userType") == "1") {
      setEditedRow(row);
      setEditedDescription(row.description);
      setEditedQuantity(row.quantity);
      setEditedWages(row.wages);
      setEditedWeight(row.weight);
      setEditedStatus(row.is_reviewed);
      setEditedOrderDate(row.order_date);
      setEditOpen(true);
    } else {
      if (row.inserted_by == insertedBy()) {
        setEditedRow(row);
        setEditedDescription(row.description);
        setEditedQuantity(row.quantity);
        setEditedWages(row.wages);
        setEditedWeight(row.weight);
        setEditedStatus(row.is_reviewed);
        setEditedOrderDate(row.order_date);
        setEditOpen(true);
      } else {
        /** No Edit Option Alert */
        editOptionAlert();
      }
    }
  };

  const handleEditClose = () => {
    setEditOpen(false);
  };


  const getRowClassName = (params) => {
    return "customRow"; // Return the class name for row styling
  };

  useEffect(() => {
    
    fetchOrdersData();
  }, []);

  const fetchOrdersData = async () => {
    try {
      const response = await axios.get(`${baseURL}/orders/getOrders`);
      let filteredRows;
      if (response.data.mData) {
        if (sessionStorage.getItem("userType") == 1) {
          filteredRows = response.data.mData.filter(
            (row) => row.is_delete !== 1
          );
          // return filteredRows;
          setRows(filteredRows);
        } else {
          filteredRows = response.data.mData.filter(
            (row) =>
              row.is_delete !== 1 &&
              row.is_reviewed != 1 &&
              row.order_status == 0
          );

          // return filteredRows;
          setRows(filteredRows);
        }
      }
      localStorage.setItem("lenOfOrders", filteredRows.length);
    } catch (error) {
      console.log(error);
    }
  };


  const handleFromDateChange = (e) => {
    setFromDate(e.target.value);
  };

  const handleToDateChange = (e) => {
    setToDate(e.target.value);
  };

  

  const filterRows = () => {
    const searchString = searchValue ? searchValue.contact_person_name : "";
    let filteredRows =
      rows.length > 0
        ? rows
        : [].filter((row) => row.contact_person_name.includes(searchString));
    

    if (fromDate && toDate) {
      const fromDateObj = new Date(fromDate + "T00:00:00"); // Ensure date format includes time for proper comparison
      const toDateObj = new Date(toDate + "T23:59:59");

      filteredRows = filteredRows.filter((row) => {
        const orderDate = new Date(row.order_date);
        return orderDate >= fromDateObj && orderDate <= toDateObj;
      });
    }

    return filteredRows;
  };

  const handleEditSubmit = async () => {
    try {
      // Construct the updated order object including order_id
      const updatedOrder = {
        order_date: editedOrderDate,
        order_id: editedRow.order_id,
        description: editedDescription,
        quantity: editedQuantity,
        wages: editedWages,
        weight: editedWeight,
      };

      // Update the row with the edited data
      const updatedRows = rows.map((row) => {
        if (row.order_id === editedRow.order_id) {
          return {
            ...row,
            order_date: editedOrderDate,
            description: editedDescription,
            quantity: editedQuantity,
            wages: editedWages,
            weight: editedWeight,
          };
        }
        return row;
      });
      setRows(updatedRows);

      // Send updated data to API
      const response = await axios.post(
        `${bulkOrders}/orders/updateOrders`,
        updatedOrder
      );

      // Handle success or failure
      if (response.data.mStatus === 200) {
        alert("Order updated successfully");
      } else {
        alert("Failed to update order");
      }

      handleEditClose();
    } catch (error) {
      console.error("Error updating order:", error);
    }
  };

  const columns = [
    {
      field: "slno",
      headerName: "Sl. No",
      width: 80,
      headerClassName: "customHeader",
      renderCell: (params) => {
        return params.row.slno;
      },
    },
    {
      field: "customer_id",
      headerName: "Customer ID",
      width: 120,
      headerClassName: "customHeader",
      cellClassName: "customCell",
    },
    {
      field: "contact_person_name",
      headerName: "Customer Name",
      width: 180,
      headerClassName: "customHeader",
      cellClassName: "customCell",
    },
    {
      field: "description",
      headerName: "Ordered Product",
      width: 140,
      headerClassName: "customHeader",
      cellClassName: "customCell",
    },
    {
      field: "quantity",
      headerName: "Quantity",
      width: 110,
      headerClassName: "customHeader",
      cellClassName: "customCell",
    },
    {
      field: "wages",
      headerName: "Wages",
      width: 120,
      headerClassName: "customHeader",
      cellClassName: "customCell",
    },
    {
      field: "weight",
      headerName: "Weights",
      width: 120,
      headerClassName: "customHeader",
      cellClassName: "customCell",
    },
    {
      field: "status",
      headerName: "Is Reviewed",
      width: 130,
      headerClassName: "customHeader",
      cellClassName: "customCell",
      renderCell: (params) => {
        return (
          <span
            style={{
              color: params.row.is_reviewed === 1 ? "green" : "red",
              cursor: "pointer",
            }}
            onClick={() => handleStatusClick(params.row)}
          >
            {params.row.is_reviewed === 1 ? "Approved" : "Pending"}
          </span>
        );
      },
    },
    {
      field: "order_date",
      headerName: "Order Date",
      width: 120,
      headerClassName: "customHeader",
      cellClassName: "customCell",
      valueFormatter: (params) => {
        // Extract the date part from the ISO string
        const date = new Date(params.value);
        return date.toLocaleDateString(); // Format the date as a string
      },
    },
    {
      field: "Action",
      headerName: "Action",
      width: 200,
      headerClassName: "customHeader",
      cellClassName: "customCell",
      renderCell: (params) => {
        // const userType = 2;  Replace with actual logic to get user type

        // Check if the action buttons should be hidden
        if (
          sessionStorage.getItem("userType") != "1" &&
          params.row.is_reviewed === 1
        ) {
          return null;
        }

        return (
          <div>
            {/* Replace these with your desired icons */}
            <BorderColorIcon
              style={{ cursor: "pointer", marginRight: "5px" }}
              onClick={() => handleEditOpen(params.row)}
            />
            <DeleteIcon
              style={{ cursor: "pointer", marginRight: "5px" }}
              onClick={() =>
                handleOrdersDelete(rows, params.row.order_id, fetchOrdersData)
              }
            />
          </div>
        );
      },
    },
  ];

  // Define columns based on user type
  let column = [...columns];
  if (sessionStorage.getItem("userType") === "1") {
    column.splice(6, 0, {
      field: "order_status",
      headerName: "Order Status",
      width: 130,
      headerClassName: "customHeader",
      cellClassName: "customCell",
      renderCell: (params) => {
        if (params.row.order_status === 2) {
          return (
            <span
              onClick={() => handleOrderStatusClick(params.row)}
              style={{ color: "green" }}
            >
              Closed
            </span>
          );
        } else if (
          params.row.order_status === 0 ||
          params.row.order_status === 1
        ) {
          return (
            <span
              onClick={() => handleOrderStatusClick(params.row)}
              style={{ color: "red" }}
            >
              Pending
            </span>
          );
        }
      },
    });
  }

  useEffect(() => {
    fetchCustomerOptionData("skvOrders", setCustomerOptions); // Fetch customer data on component mount
  }, [setCustomerOptions]);

  const handleCancel = () => {
    setOrderStatusDialogOpen(false);
    setError("");
  };

  const handleStatusCancel = () => {
    setStatusDialogOpen(false);
    setErrorStatus("");
  };

  const handleNavigate = () => {
    navigate("/bulk-order", { state: { customerOptions: customerOptions } });
  };

  return (
    // <Box sx={{ height: 500, width: "97%", marginX: "2%" }}>
    //   <div style={{ display: "flex", justifyContent: "space-between" }}>
    //     <Button
    //       onClick={handleNavigate}
    //       startIcon={<AddIcon />}
    //       variant="contained"
    //       sx={{ mt: 4 }}
    //     >
    //       Add Orders
    //     </Button>
    //     <Autocomplete
    //       id="customer-search"
    //       options={customerOptions}
    //       getOptionLabel={(option) => option.contact_person_name}
    //       value={searchValue}
    //       onChange={(event, newValue) => {
    //         setSearchValue(newValue);
    //       }}
    //       renderInput={(params) => (
    //         <TextField
    //           {...params}
    //           sx={{ width: "200px" }}
    //           label="Search by Customer Name"
    //         />
    //       )}
    //     />
    //     <TextField
    //       label="From Date"
    //       type="date"
    //       variant="outlined"
    //       fullWidth
    //       value={fromDate}
    //       onChange={handleFromDateChange}
    //       sx={{ width: "200px" }}
    //       style={{ marginBottom: 20 }}
    //       InputLabelProps={{
    //         shrink: true,
    //       }}
    //     />
    //     <TextField
    //       label="To Date"
    //       type="date"
    //       variant="outlined"
    //       fullWidth
    //       value={toDate}
    //       onChange={handleToDateChange}
    //       sx={{ width: "200px" }}
    //       style={{ marginBottom: 20 }}
    //       InputLabelProps={{
    //         shrink: true,
    //       }}
    //     />
    //     <Button
    //       onClick={handleNavigateOverview}
    //       variant="contained"
    //       sx={{ mt: 4 }}
    //     >
    //       Overview
    //     </Button>
    //   </div>

    //   {/* Is Reviewed  Status Update Dialog */}
    //   <Dialog
    //     open={statusDialogOpen}
    //     onClose={() => setStatusDialogOpen(false)}
    //   >
    //     <DialogTitle>Update Status</DialogTitle>
    //     <DialogContent>
    //       <FormControl
    //         margin="dense"
    //         rol
    //         fullWidth={false}
    //         style={{ width: "300px" }}
    //       >
    //         <InputLabel>Status</InputLabel>
    //         <Select
    //           value={editedStatus}
    //           onChange={(e) => setEditedStatus(e.target.value)}
    //         >
    //           <MenuItem value={1}>Approved</MenuItem>
    //           <MenuItem value={2}>Pending</MenuItem>
    //         </Select>
    //       </FormControl>
    //       {statusError && <p style={{ color: "red" }}>{statusError}</p>}
    //     </DialogContent>
    //     <DialogActions>
    //       <Button onClick={handleStatusCancel}>Cancel</Button>
    //       <Button onClick={handleStatusUpdate}>Save</Button>
    //     </DialogActions>
    //   </Dialog>

    //   {/* Order Status Update Dialog */}
    //   {sessionStorage.getItem("userType") === "1" ? (
    //     <Dialog
    //       open={orderStatusDialogOpen}
    //       onClose={() => setOrderStatusDialogOpen(false)}
    //     >
    //       <DialogTitle>Update Order Status</DialogTitle>
    //       <DialogContent>
    //         <FormControl
    //           margin="dense"
    //           rol
    //           fullWidth={false}
    //           style={{ width: "300px" }}
    //         >
    //           <InputLabel>Order Status</InputLabel>
    //           <Select
    //             value={editedOrderStatus === null ? "" : editedOrderStatus}
    //             onChange={(e) =>
    //               setEditedOrderStatus(
    //                 e.target.value === "" ? null : e.target.value
    //               )
    //             }
    //           >
    //             <MenuItem value={2}>Closed</MenuItem>
    //             <MenuItem value={0}>Pending</MenuItem>
    //           </Select>
    //         </FormControl>
    //         {error && <p style={{ color: "red" }}>{error}</p>}
    //       </DialogContent>
    //       <DialogActions>
    //         <Button onClick={handleCancel}>Cancel</Button>
    //         <Button onClick={handleOrderStatus}>Save</Button>
    //       </DialogActions>
    //     </Dialog>
    //   ) : (
    //     <Dialog
    //       open={orderStatusDialogOpen}
    //       onClose={() => setOrderStatusDialogOpen(false)}
    //     >
    //       <DialogTitle>Update Order Status</DialogTitle>
    //       <DialogContent>
    //         <FormControl fullWidth margin="dense">
    //           <InputLabel>Order Status</InputLabel>
    //           <Select
    //             value={editedOrderStatus == 0 ? "Pending" : "InProgress"}
    //             onChange={(e) => setEditedOrderStatus(e.target.value)}
    //           >
    //             <MenuItem value={0}>Pending</MenuItem>
    //             <MenuItem value={1}>InProgress</MenuItem>
    //           </Select>
    //         </FormControl>
    //       </DialogContent>
    //       <DialogActions>
    //         <Button onClick={handleCancel}>Cancel</Button>
    //         <Button onClick={handleOrderStatus}>Save</Button>
    //       </DialogActions>
    //     </Dialog>
    //   )}

    //   {/* Edit popup component */}
    //   <Dialog open={editOpen} onClose={handleEditClose}>
    //     <DialogTitle>Edit Orders</DialogTitle>
    //     <DialogContent>
    //       <TextField
    //         margin="dense"
    //         label="Order Date"
    //         type="date"
    //         fullWidth
    //         required
    //         name="order_date"
    //         value={editedOrderDate}
    //         onChange={(e) => setEditedOrderDate(e.target.value)}
    //         InputLabelProps={{
    //           shrink: true,
    //         }}
    //       />
    //       <FormControl fullWidth margin="dense">
    //         {/* <InputLabel>Description</InputLabel> */}
    //         <Autocomplete
    //           fullWidth
    //           margin="dense"
    //           options={options}
    //           getOptionLabel={(option) => option.label}
    //           value={
    //             options.find((option) => option.value === editedDescription) ||
    //             null
    //           }
    //           onChange={(event, newValue) => {
    //             setEditedDescription(newValue ? newValue.value : "");
    //           }}
    //           renderInput={(params) => (
    //             <TextField
    //               {...params}
    //               label="Description"
    //               required
    //               fullWidth
    //               margin="dense"
    //             />
    //           )}
    //         />
    //       </FormControl>
    //       <TextField
    //         margin="dense"
    //         label="Quantity"
    //         fullWidth
    //         name="quantity"
    //         value={editedQuantity}
    //         onChange={(e) => setEditedQuantity(e.target.value)}
    //       />
    //       <TextField
    //         margin="dense"
    //         label="Wages"
    //         fullWidth
    //         required
    //         name="wages"
    //         value={editedWages}
    //         onChange={(e) => setEditedWages(e.target.value)}
    //       />
    //       <TextField
    //         margin="dense"
    //         label="Weight"
    //         fullWidth
    //         required
    //         name="weight"
    //         value={editedWeight}
    //         onChange={(e) => setEditedWeight(e.target.value)}
    //       />
    //     </DialogContent>
    //     <DialogActions>
    //       <Button onClick={handleEditClose}>Cancel</Button>
    //       <Button onClick={handleEditSubmit}>Save</Button>
    //     </DialogActions>
    //   </Dialog>

    //   <DataGrid
    //     rows={filterRows().map((row, index) => ({ ...row, slno: index + 1 }))}
    //     columns={column}
    //     getRowClassName={getRowClassName} // Apply row styling
    //     initialState={{
    //       pagination: {
    //         paginationModel: {
    //           pageSize: 5,
    //         },
    //       },
    //     }}
    //     pageSizeOptions={[5]}
    //     getRowId={(row) => row.order_id}
    //   />
    // </Box>

    <Box sx={{ height: 500, width: "97%", marginX: "2%" }}>
      <Grid container spacing={2} alignItems="center" sx={{ mb: 4 }}>
        <Grid item xs={12} md={3}>
          <Button
            onClick={handleNavigate}
            startIcon={<AddIcon />}
            variant="contained"
            fullWidth
            sx={{ height: "100%" }}
          >
            Add Orders
          </Button>
        </Grid>
        <Grid item xs={12} md={3}>
          <Autocomplete
            id="customer-search"
            options={customerOptions}
            getOptionLabel={(option) => option.contact_person_name}
            value={searchValue}
            onChange={(event, newValue) => {
              setSearchValue(newValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Search by Customer Name"
                variant="outlined"
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <TextField
            label="From Date"
            type="date"
            variant="outlined"
            value={fromDate}
            onChange={handleFromDateChange}
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <TextField
            label="To Date"
            type="date"
            variant="outlined"
            value={toDate}
            onChange={handleToDateChange}
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <Button
            onClick={handleNavigateOverview}
            variant="contained"
            fullWidth
            sx={{ height: "100%" }}
          >
            Overview
          </Button>
        </Grid>
      </Grid>

      {/* Is Reviewed Status Update Dialog */}
      <Dialog
        open={statusDialogOpen}
        onClose={() => setStatusDialogOpen(false)}
      >
        <DialogTitle>Update Status</DialogTitle>
        <DialogContent>
          <FormControl margin="dense" fullWidth>
            <InputLabel>Status</InputLabel>
            <Select
              value={editedStatus}
              onChange={(e) => setEditedStatus(e.target.value)}
            >
              <MenuItem value={1}>Approved</MenuItem>
              <MenuItem value={2}>Pending</MenuItem>
            </Select>
          </FormControl>
          {statusError && <p style={{ color: "red" }}>{statusError}</p>}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleStatusCancel}>Cancel</Button>
          <Button onClick={handleStatusUpdate}>Save</Button>
        </DialogActions>
      </Dialog>

      {/* Order Status Update Dialog */}
      {sessionStorage.getItem("userType") === "1" ? (
        <Dialog
          open={orderStatusDialogOpen}
          onClose={() => setOrderStatusDialogOpen(false)}
        >
          <DialogTitle>Update Order Status</DialogTitle>
          <DialogContent>
            <FormControl margin="dense" fullWidth>
              <InputLabel>Order Status</InputLabel>
              <Select
                value={editedOrderStatus === null ? "" : editedOrderStatus}
                onChange={(e) =>
                  setEditedOrderStatus(
                    e.target.value === "" ? null : e.target.value
                  )
                }
              >
                <MenuItem value={2}>Closed</MenuItem>
                <MenuItem value={0}>Pending</MenuItem>
              </Select>
            </FormControl>
            {error && <p style={{ color: "red" }}>{error}</p>}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCancel}>Cancel</Button>
            <Button onClick={handleOrderStatus}>Save</Button>
          </DialogActions>
        </Dialog>
      ) : (
        <Dialog
          open={orderStatusDialogOpen}
          onClose={() => setOrderStatusDialogOpen(false)}
        >
          <DialogTitle>Update Order Status</DialogTitle>
          <DialogContent>
            <FormControl fullWidth margin="dense">
              <InputLabel>Order Status</InputLabel>
              <Select
                value={editedOrderStatus == 0 ? "Pending" : "InProgress"}
                onChange={(e) => setEditedOrderStatus(e.target.value)}
              >
                <MenuItem value={0}>Pending</MenuItem>
                <MenuItem value={1}>InProgress</MenuItem>
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCancel}>Cancel</Button>
            <Button onClick={handleOrderStatus}>Save</Button>
          </DialogActions>
        </Dialog>
      )}

      {/* Edit popup component */}
      <Dialog open={editOpen} onClose={handleEditClose}>
        <DialogTitle>Edit Orders</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            label="Order Date"
            type="date"
            fullWidth
            required
            name="order_date"
            value={editedOrderDate}
            onChange={(e) => setEditedOrderDate(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <FormControl fullWidth margin="dense">
            <Autocomplete
              fullWidth
              margin="dense"
              options={options}
              getOptionLabel={(option) => option.label}
              value={
                options.find((option) => option.value === editedDescription) ||
                null
              }
              onChange={(event, newValue) => {
                setEditedDescription(newValue ? newValue.value : "");
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Description"
                  required
                  fullWidth
                  margin="dense"
                />
              )}
            />
          </FormControl>
          <TextField
            margin="dense"
            label="Quantity"
            fullWidth
            name="quantity"
            value={editedQuantity}
            onChange={(e) => setEditedQuantity(e.target.value)}
          />
          <TextField
            margin="dense"
            label="Wages"
            fullWidth
            required
            name="wages"
            value={editedWages}
            onChange={(e) => setEditedWages(e.target.value)}
          />
          <TextField
            margin="dense"
            label="Weight"
            fullWidth
            required
            name="weight"
            value={editedWeight}
            onChange={(e) => setEditedWeight(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEditClose}>Cancel</Button>
          <Button onClick={handleEditSubmit}>Save</Button>
        </DialogActions>
      </Dialog>

      <DataGrid
        rows={filterRows().map((row, index) => ({ ...row, slno: index + 1 }))}
        columns={column}
        getRowClassName={getRowClassName} // Apply row styling
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 5,
            },
          },
        }}
        pageSizeOptions={[5]}
        getRowId={(row) => row.order_id}
      />
    </Box>
  );
}
