import Swal from "sweetalert2";

export const credentialError = () => {
  return Swal.fire({
    title: "Invalid Credentials",
    showClass: {
      popup: `
          animate__animated
          animate__fadeInUp
          animate__faster
        `,
    },
    hideClass: {
      popup: `
          animate__animated
          animate__fadeOutDown
          animate__faster
        `,
    },
  });
};

export const credentialSuccess = () => {
  return Swal.fire({
    position: "top-end",
    icon: "success",
    title: "Authentication Successful",
    showConfirmButton: false,
    timer: 1500,
  });
};

export const editOptionAlert = () => {
  return Swal.fire({
    icon: "error",
    title: "Oops...",
    text: "You Can't Edit The Data",
  });
};

export const limitExceedsAlert = () => {
  return Swal.fire({
    icon: "error",
    title: "Limit Exceeded",
    text: "You can only add up to 20 orders at a time.",
  });
};

export const invalidDateFormatAlert = () => {
  return Swal.fire({
    icon: "error",
    title: "Invalid Date",
    text: "Please Enter The Valid Date",
  });
};

export const changeReviewedStsAlert = () => {
  return Swal.fire({
    icon: "error",
    title: "Oops...",
    text: "Please Change Is Reviewed Status To Approved",
  });
};

export const selectTheCustomerAlert = () => {
  return Swal.fire({
    icon: "error",
    title: "Customer Not Selected",
    text: "Please Select The Customer",
  });
};

