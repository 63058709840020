/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Grid,
} from "@mui/material";
import axios from "axios";
import { currentDate } from "../support/receiptGeneration";
import { baseURL } from "../support/baseURL";

export default function DateReceiptTable() {
  const [customerOptions, setCustomerOptions] = useState([]);
  const [customerOrderedData, setCustomerOrderedData] = useState([]);
  const [newCustomer] = useState({ customer_id: "" });
  const [orderStatusFilter, setOrderStatusFilter] = useState("all"); // New state for order status filter
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [purityFilter, setPurityFilter] = useState("");

  // Create a function to handle purity filter change
  const handlePurityFilterChange = (e) => {
    setPurityFilter(e.target.value);
  };

  const fetchCustomerOrderedData = async () => {
    try {
      const response = await axios.get(`${baseURL}/orders/getOrders`);

      if (response.data.mStatus === 200) {
        const orderedDataWithIds = response.data.mData.map((row, index) => ({
          ...row,
          id: index + 1, // Generate unique ID for each row
        }));
        const nonDeletedData = orderedDataWithIds.filter(
          (items) => items.is_delete == 0
        );
        setCustomerOrderedData(nonDeletedData);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    fetchCustomerOrderedData();
  }, []);
  const handleOrderStatusFilterChange = (e) => {
    setOrderStatusFilter(e.target.value);
  };

  const handleFromDateChange = (e) => {
    setFromDate(e.target.value);
  };

  const handleToDateChange = (e) => {
    setToDate(e.target.value);
  };

  useEffect(() => {
    fetchCustomerData();
  }, []);

  const fetchCustomerData = async () => {
    try {
      const response = await axios.get(`${baseURL}/customers/getCustomer`);
      if (response.data.mData) {
        const activeCustomers = response.data.mData.filter(
          (data) => data.is_delete !== 1
        );
        const skvCustomers = activeCustomers.filter(
          (data) => data.purity === "SKV/5" || data.purity === "SKV?80"
        );
        setCustomerOptions(skvCustomers);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const filteredCustomerOrderedData = customerOrderedData.filter((item) => {
    if (orderStatusFilter !== "all") {
      if (item.order_status.toString() !== orderStatusFilter) {
        return false;
      }
    }

    if (purityFilter && item.purity !== purityFilter) {
      return false;
    }

    // Filter by date range
    if (fromDate && toDate) {
      const OD = item.order_date === null ? "0000-00-00" : item.order_date;
      const orderDate = new Date(OD.split("T")[0]);
      const fromDateObj = new Date(fromDate);
      const toDateObj = new Date(toDate);

      if (orderDate < fromDateObj || orderDate > toDateObj) {
        return false;
      }
    }

    return true;
  });


  const generateReceipt = () => {
    // Group orders by customer_id
    const groupedByCustomer = filteredCustomerOrderedData.reduce(
      (acc, order) => {
        if (!acc[order.customer_id]) {
          acc[order.customer_id] = [];
        }
        acc[order.customer_id].push(order);
        return acc;
      },
      {}
    );

    const receiptContent = Object.entries(groupedByCustomer)
      .map(([customerId, orders]) => {
        const customerName =
          customerOptions.find(
            (customer) => customer.customer_id === customerId
          )?.contact_person_name || "";

        const reviewedData = orders.filter((item) => item.is_reviewed == 1);
        const filteredData = reviewedData.filter(
          (item) => item.is_delete === 0
        );

        filteredData.sort((a, b) => {
          const dateA = new Date(processedDate(a.order_date));
          const dateB = new Date(processedDate(b.order_date));
          return dateA - dateB;
        });

        const customerReceiptContent = filteredData.map((item) => {
          const orderDate = new Date(item.order_date);
          const day = orderDate.getDate();
          const month = orderDate.getMonth() + 1;
          const formattedDay = day < 10 ? "0" + day : day;
          const formattedMonth = month < 10 ? "0" + month : month;
          const formattedDate = formattedDay + "-" + formattedMonth;

          return {
            customer: item.contact_person_name,
            order_id: item.order_id,
            product: item.description,
            weight: item.weight,
            orderDate: formattedDate,
          };
        });

        const totalWeight = customerReceiptContent
          .reduce((total, item) => total + parseFloat(item.weight), 0)
          .toFixed(3);

        let adjustedTotalWeight = parseFloat(totalWeight);
        const productSummary = {};
        customerReceiptContent.forEach((item) => {
          const productName = item.product.trim();
          if (
            productName === "70" ||
            productName === "80" ||
            productName === "WS"
          ) {
            if (!productSummary[productName]) {
              productSummary[productName] = 0;
            }
            const weight = parseFloat(item.weight);
            productSummary[productName] += weight;
            adjustedTotalWeight -= weight;
          }
        });

        const adjustedTotalWeightStr = adjustedTotalWeight.toFixed(3);

        return `
            <h3 style="margin-top : 3px">${customerName}</h3>
            <table>
                <tr>
                  <th>Date</th>
                  <th>Customer</th>
                  <th>Weight</th>
                  <th>Details</th>
                </tr>
                ${customerReceiptContent
                  .map(
                    (item) => `
                  <tr>
                    <td>${item.orderDate}</td>
                    <td>${item.customer}</td>
                    <td>${item.weight}</td>
                    <td>${item.product}</td>
                  </tr>
                `
                  )
                  .join("")}
                <tr>
                  <td colspan="3" style="text-align: right;">Total Weight:</td>
                  <td>${adjustedTotalWeightStr}</td>
                </tr> 
                <tr>
                  <td colspan="3" style="text-align: right;">OB:</td>
                  <td></td>
                </tr>
                ${Object.keys(productSummary)
                  .map(
                    (productName) => `
                  <tr>
                    <td colspan="3" style="text-align: right;"><strong>${productName}</strong> Weight:</td>
                    <td>${productSummary[productName].toFixed(3)}</td>
                  </tr>
                `
                  )
                  .join("")}
            </table>
            <h1>----✂---------------------✂----------------------✂---------------------------✂---------------------------</h1>
          `;
      })
      .join("");

    // Create a new window for printing
    const printWindow = window.open("", "_blank");
    printWindow.document.write(`
          <html>
            <head>
              <title>Print Receipt</title>
              <style>
                body {
                  font-family: Arial, sans-serif;
                  font-size: 15px;
                  margin-top: 1%;
                }
                table {
                  border-collapse: collapse;
                  width: 60%;
                  font-size: 15px;
                }
                th, td {
                  border: 1px solid black;
                  padding: 8px;
                  text-align: left;
                }
                th {
                  background-color: #f2f2f2;
                }
                button {
                  margin-top: 20px;
                  padding: 10px 20px;
                  background-color: #4caf50;
                  color: white;
                  border: none;
                  cursor: pointer;
                }
                button:hover {
                  background-color: #45a049;
                }
                h3,h4,p,h1{
                  text-align:center;
                  font-size:15px;
                }
                .spacing{
                  margin: 1px;
                }
              </style>
            </head>
            <body>
              <p style="text-align:center; font-size:15px">உ</p>
              <div style="display : flex; justify-content : space-between ; margin-top : -20px">
                <h5 style="font-size:15px">Date: ${currentDate()}</h5>
              </div>
              ${receiptContent}
              <h3>Thank You</h3>
              <h3>SKV</h3>
            </body>
          </html>
        `);
    printWindow.document.close();
    printWindow.print();
    // ownerReceipt();

    if (printWindow) {
      printWindow.close();
    }
  };


  const columns = [
    {
      field: "order_id",
      headerName: "Order ID",
      width: 150,
      headerClassName: "customHeader",
      cellClassName: "customCell",
    },
    {
      field: "contact_person_name",
      headerName: "Name",
      width: 200,
      headerClassName: "customHeader",
      cellClassName: "customCell",
    },
    {
      field: "purity",
      headerName: "Group",
      width: 200,
      headerClassName: "customHeader",
      cellClassName: "customCell",
    },
    {
      field: "description",
      headerName: "Description",
      width: 200,
      headerClassName: "customHeader",
      cellClassName: "customCell",
    },
    {
      field: "weight",
      headerName: "Weight",
      width: 150,
      headerClassName: "customHeader",
      cellClassName: "customCell",
    },
    {
      field: "order_status",
      headerName: "Order Status",
      width: 150,
      headerClassName: "customHeader",
      cellClassName: "customCell",
      renderCell: (params) => {
        switch (params.value) {
          case 0:
            return "Pending";
          case 1:
            return "InProgress";
          case 2:
            return "Completed";
          default:
            return null;
        }
      },
    },
    {
      field: "is_reviewed",
      headerName: "Is Reviewed",
      width: 150,
      headerClassName: "customHeader",
      cellClassName: "customCell",
      renderCell: (params) => {
        return params.value === 0 ? "Pending" : "Approved";
      },
    },
    {
      field: "order_date",
      headerName: "Ordered Date",
      width: 200,
      headerClassName: "customHeader",
      cellClassName: "customCell",
      renderCell: (params) => {
        return processedDate(params.value);
      },
    },
  ];
  const processedDate = (date) => {
    if (date == null) {
      return "0000-00-00";
    }
    const formattedDate = date.split("T")[0];
    return formattedDate;
  };

  const generateReceiptSummary = () => {
    const groupedByCustomer = filteredCustomerOrderedData.reduce(
      (acc, order) => {
        if (!acc[order.customer_id]) {
          acc[order.customer_id] = [];
        }
        acc[order.customer_id].push(order);
        return acc;
      },
      {}
    );

    const receiptContent = Object.entries(groupedByCustomer)
      .map(([customerId, orders]) => {
        const customerName =
          customerOptions.find((customer) => customer.customer_id == customerId)
            ?.contact_person_name || "Unknown Customer";

        const totalWeight = orders
          .reduce((total, item) => total + parseFloat(item.weight), 0)
          .toFixed(3);

        return `
          <tr>
          <td>${customerId}</td>
            <td>${customerName}</td>
            <td>${totalWeight} g</td>
          </tr>
        `;
      })
      .join("");

    const printWindow = window.open("", "_blank");
    printWindow.document.write(`
      <html>
        <head>
          <title>Receipt Summary</title>
          <style>
            body {
              font-family: Arial, sans-serif;
              font-size: 15px;
            }
            table {
              border-collapse: collapse;
              width: 70%;
            }
            th, td {
              border: 1px solid black;
              padding: 8px;
              text-align: left;
            }
            th {
              background-color: #f2f2f2;
            }
          </style>
        </head>
        <body>
          <h2 style="font-size:15px">Date: ${currentDate()}</h2>
          <table>
            <thead>
              <tr>
              <th>Customer ID</th>
                <th>Customer Name</th>
                <th>Total Weight</th>
              </tr>
            </thead>
            <tbody>
              ${receiptContent}
            </tbody>
          </table>
        </body>
      </html>
    `);
    printWindow.document.close();
    printWindow.print();
  };

  return (
    <Box sx={{ height: 500, width: "97%", marginX: "2%" }}>
      {/* <div style={{ display: "flex", justifyContent: "space-between" }}>
        <FormControl rol fullWidth={false} style={{ width: "250px" }}>
          <InputLabel>Order Status</InputLabel>
          <Select
            value={orderStatusFilter}
            onChange={handleOrderStatusFilterChange}
          >
            <MenuItem value="all">All</MenuItem>
            <MenuItem value="0">Pending</MenuItem>
            <MenuItem value="2">Closed</MenuItem>
          </Select>
        </FormControl>
        <FormControl rol fullWidth={false} style={{ width: "250px" }}>
          <InputLabel>Group</InputLabel>
          <Select value={purityFilter} onChange={handlePurityFilterChange}>
            <MenuItem value="">All</MenuItem>
            <MenuItem value="SKV/5">SKV/5</MenuItem>
            <MenuItem value="SKV?80">SKV?80</MenuItem>
          </Select>
        </FormControl>
        <TextField
          id="from-date"
          label="From Date"
          type="date"
          value={fromDate}
          onChange={handleFromDateChange}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          id="to-date"
          label="To Date"
          type="date"
          value={toDate}
          onChange={handleToDateChange}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <Button variant="contained" color="primary" onClick={generateReceipt}>
          Print Receipt
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={generateReceiptSummary}
        >
          Generate Summary
        </Button>
      </div> */}
      {/* Use Grid container for better responsiveness and spacing */}
      <Grid container spacing={2} alignItems="center">
        {/* Order Status Select */}
        <Grid item xs={12} sm={6} md={2.4}>
          <FormControl fullWidth>
            <InputLabel>Order Status</InputLabel>
            <Select
              value={orderStatusFilter}
              onChange={handleOrderStatusFilterChange}
            >
              <MenuItem value="all">All</MenuItem>
              <MenuItem value="0">Pending</MenuItem>
              <MenuItem value="2">Closed</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        {/* Group Select */}
        <Grid item xs={12} sm={6} md={2.4}>
          <FormControl fullWidth>
            <InputLabel>Group</InputLabel>
            <Select value={purityFilter} onChange={handlePurityFilterChange}>
              <MenuItem value="">All</MenuItem>
              <MenuItem value="SKV/5">SKV/5</MenuItem>
              <MenuItem value="SKV?80">SKV?80</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        {/* From Date Picker */}
        <Grid item xs={12} sm={6} md={2.4}>
          <TextField
            fullWidth
            id="from-date"
            label="From Date"
            type="date"
            value={fromDate}
            onChange={handleFromDateChange}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>

        {/* To Date Picker */}
        <Grid item xs={12} sm={6} md={2.4}>
          <TextField
            fullWidth
            id="to-date"
            label="To Date"
            type="date"
            value={toDate}
            onChange={handleToDateChange}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>

        {/* Print Receipt Button */}
        <Grid item xs={12} sm={6} md={2.4}>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={generateReceipt}
          >
            Print Receipt
          </Button>
        </Grid>

        {/* Generate Summary Button */}
        <Grid item xs={12} sm={6} md={2.4}>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={generateReceiptSummary}
          >
            Generate Summary
          </Button>
        </Grid>
      </Grid>
      <DataGrid
        rows={filteredCustomerOrderedData}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 5,
            },
          },
        }}
        pageSizeOptions={[5]}
      />
    </Box>
  );
}
