import CryptoJS from "crypto-js";

// import { fShortenNumber } from 'src/utils/format-number';

const secretKey = "your-secret-key"; // Change this to your own secret key

export const encryptData = (rawData) => {
  return CryptoJS.AES.encrypt(rawData, secretKey).toString();
};

export const decryptData = (encryptedData) => {
  try {
    const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
    return bytes.toString(CryptoJS.enc.Utf8);
  } catch (error) {
    console.error("Decryption error:", error.message);
    return null;
  }
};

export const formatDate = (date) => {
  return date.split("T")[0];
};
