/* eslint-disable no-unused-vars */
import * as React from "react";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import axios from "axios";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Select, // Added Select component
  MenuItem, // Added MenuItem component
  FormControl, // Added FormControl component
  InputLabel, // Added InputLabel component
  Grid,
} from "@mui/material";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import PeopleAltTwoToneIcon from "@mui/icons-material/PeopleAltTwoTone";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import DeleteIcon from "@mui/icons-material/Delete";
import "../../index.css";
import * as XLSX from "xlsx";
import { handleDeleteUser } from "../support/deleteData";
import { insertedBy } from "../support/sessionUserName";
import { baseURL } from "../support/baseURL";

export default function UserRegistrationTable() {
  const [rows, setRows] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [editedRow, setEditedRow] = useState(null);
  const [editedName, setEditedName] = useState("");
  const [editedPassword, setEditedPassword] = useState("");
  const [editedPhoneNumber, setEditedPhoneNumber] = useState("");
  const [editedAltPhoneNumber, setEditedAltPhoneNumber] = useState("");
  const [editedEmail, setEditedEmail] = useState("");
  const [editedAadhar, setEditedAadhar] = useState("");
  const [editedUserType, setEditedUserType] = useState("");
  const [errorName, setErrorName] = useState("");
  const [errorPhone, setErrorPhone] = useState("");
  const [errorAltPhn, setErrorAltPhn] = useState("");
  const [errorEmail, setErrorEmail] = useState("");
  const [errorAadhar, setErrorAadhar] = useState("");
  const [errorUserType, setErrorUserType] = useState("");

  const [newCustomer, setNewCustomer] = useState({
    name: "",
    phone_number: "",
    alternate_phone_number: "",
    email: "",
    aadhar_number: "",
    user_type: "",
    inserted_by: insertedBy(),
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewCustomer({ ...newCustomer, [name]: value });
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setNewCustomer({
      name: "",
      phone_number: "",
      alternate_phone_number: "",
      email: "",
      aadhar_number: "",
      user_type: "",
      inserted_by: insertedBy(),
    });
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleEditOpen = (row) => {
    setEditedRow(row);
    setEditedName(row.name);
    setEditedPassword(row.password);
    setEditedPhoneNumber(row.phone_number);
    setEditedAltPhoneNumber(row.alternate_phone_number);
    setEditedEmail(row.email);
    setEditedAadhar(row.aadhar_number);
    setEditedUserType(row.user_type);
    setEditOpen(true);
  };

  const handleEditClose = () => {
    setEditOpen(false);
  };
  const handleAddCustomer = async () => {
    // Reset error messages
    setErrorName("");
    setErrorPhone("");
    setErrorAltPhn("");
    setErrorEmail("");
    setErrorAadhar("");
    setErrorUserType("");

    // Check for empty fields
    if (
      !newCustomer.name ||
      !newCustomer.phone_number ||
      !newCustomer.email ||
      !newCustomer.user_type
    ) {
      if (!newCustomer.name) setErrorName("Please Enter The Name");
      if (!newCustomer.phone_number)
        setErrorPhone("Please Enter The Mobile Number");

      if (!newCustomer.user_type)
        setErrorUserType("Please Enter The User Type");
      return; // Stop execution if any field is empty
    }

    // Sanitize phone numbers
    const sanitizePhoneNumber = (phoneNumber) => {
      return phoneNumber.replace(/\D/g, "");
    };

    const sanitizedPhone = sanitizePhoneNumber(newCustomer.phone_number);
    const sanitizedAltPhone = sanitizePhoneNumber(
      newCustomer.alternate_phone_number
    );

    // Check if phone numbers consist of exactly 10 digits
    if (sanitizedPhone.length !== 10) {
      setErrorPhone("Phone number must be 10 digits");
      return; // Stop execution if phone number is invalid
    }

    if (newCustomer.alternate_phone_number && sanitizedAltPhone.length !== 10) {
      setErrorAltPhn("Alternate phone number must be 10 digits");
      return; // Stop execution if alternate phone number is invalid
    }

    if (newCustomer.aadhar_number && newCustomer.aadhar_number.length !== 12) {
      setErrorAadhar("Aadhar number must be 12 digits");
      return;
    }

    try {
      // Perform API call to add user
      const addResponse = await axios.post(
        `${baseURL}/users/addUsers`,
        newCustomer
      );
      if (addResponse.data.mStatus === 400) {
        alert(addResponse.data.mData.sqlMessage);
      } else if (addResponse.data.mStatus === 200) {
        alert("Users Data Added Successfully");
        fetchedData();
        handleCloseModal();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getRowClassName = (params) => {
    return "customRow"; // Return the class name for row styling
  };

  useEffect(() => {
    fetchedData();
  }, []);

  const fetchedData = async () => {
    try {
      const response = await axios.get(`${baseURL}/users/getUsers`);
      if (response.data.mData) {
        const filteredRows = response.data.mData.filter(
          (row) => row.is_delete !== 1
        );
        setRows(filteredRows);

        localStorage.setItem("lenOfUsers", filteredRows.length);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleEditSubmit = async () => {
    try {
      // Construct the updated order object including order_id
      const updatedUser = {
        user_id: editedRow.user_id,
        name: editedName,
        password: editedPassword,
        phone_number: editedPhoneNumber,
        alternate_phone_number: editedAltPhoneNumber,
        email: editedEmail,
        aadhar_number: editedAadhar,
        user_type: editedUserType,
      };

      // Update the row with the edited data
      const updatedRows = rows.map((row) => {
        if (row.user_id === editedRow.user_id) {
          return {
            ...row,
            name: editedName,
            password: editedPassword,
            phone_number: editedPhoneNumber,
            alternate_phone_number: editedAltPhoneNumber,
            email: editedEmail,
            aadhar_number: editedAadhar,
            user_type: editedUserType,
          };
        }
        return row;
      });
      setRows(updatedRows);

      // Send updated data to API
      const response = await axios.post(
        `${baseURL}/users/updateUsers`,
        updatedUser
      );

      // Handle success or failure
      if (response.data.mStatus === 200) {
        alert("User Data updated successfully");
        fetchedData();
      } else {
        alert("Failed to update order");
      }

      handleEditClose();
    } catch (error) {
      console.error("Error updating order:", error);
    }
  };

  const columns = [
    {
      field: "slno",
      headerName: "Sl. No",
      width: 80,
      headerClassName: "customHeader",
      cellClassName: "customCell",
      renderCell: (params) => {
        return params.row.slno;
      },
    },
    {
      field: "user_id",
      headerName: "User ID",
      width: 120,
      headerClassName: "customHeader",
      cellClassName: "customCell",
    },
    {
      field: "name",
      headerName: "Name",
      width: 200,
      headerClassName: "customHeader",
      cellClassName: "customCell",
    },
    {
      field: "phone_number",
      headerName: "Phone",
      width: 150,
      headerClassName: "customHeader",
      cellClassName: "customCell",
    },
    {
      field: "alternate_phone_number",
      headerName: "Alternate Phone",
      width: 150,
      headerClassName: "customHeader",
      cellClassName: "customCell",
    },
    {
      field: "email",
      headerName: "Email",
      width: 250,
      headerClassName: "customHeader",
      cellClassName: "customCell",
    },
    {
      field: "aadhar_number",
      headerName: "Aadhar Number",
      width: 180,
      headerClassName: "customHeader",
      cellClassName: "customCell",
    },
    {
      field: "Action",
      headerName: "Action",
      width: 200,
      headerClassName: "customHeader",
      cellClassName: "customCell",
      renderCell: (params) => {
        return (
          <div>
            {/* Replace these with your desired icons */}
            <BorderColorIcon
              style={{ cursor: "pointer", marginRight: "5px" }}
              onClick={() => handleEditOpen(params.row)}
            />
            <DeleteIcon
              style={{ cursor: "pointer", marginRight: "5px" }}
              onClick={() => handleDeleteUser(params.row.user_id, fetchedData)}
            />
          </div>
        );
      },
    },
  ];

  const handleExportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(rows);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "users");
    XLSX.writeFile(workbook, "users.xlsx");
  };

  return (
    <Box sx={{ height: 500, width: "97%", marginX: "2%" }}>
      {/* <div style={{ display: "flex", justifyContent: "space-between" }}>
        
        <Button
          onClick={handleOpenModal}
          startIcon={<PeopleAltTwoToneIcon />}
          variant="contained"
          sx={{ mt: 2 }}
        >
          Add User
        </Button>
        <Button
          variant="contained"
          color="secondary"
          sx={{ height: "50px" }}
          startIcon={<DownloadOutlinedIcon />}
          onClick={handleExportToExcel}
        >
          Export to Excel
        </Button>
        <Button
          onClick={fetchedData}
          sx={{
            backgroundColor: colors.blueAccent[700],
            color: colors.grey[100],
            fontSize: "14px",
            fontWeight: "bold",
            padding: "10px 20px",
          }}
        >
          <RestoreTwoToneIcon sx={{ mr: "10px" }} />
          Refresh
        </Button>
      </div> */}
      <Grid
        container
        spacing={2}
        alignItems="center"
        justifyContent="space-between"
      >
        {/* Add User Button */}
        <Grid item xs={12} sm={6} md={3}>
          <Button
            onClick={handleOpenModal}
            startIcon={<PeopleAltTwoToneIcon />}
            variant="contained"
            fullWidth
            sx={{ paddingY: 1.5 }}
          >
            Add User
          </Button>
        </Grid>

        {/* Export to Excel Button */}
        <Grid item xs={12} sm={6} md={3}>
          <Button
            variant="contained"
            color="secondary"
            fullWidth
            startIcon={<DownloadOutlinedIcon />}
            onClick={handleExportToExcel}
            sx={{ paddingY: 1.5 }}
          >
            Export to Excel
          </Button>
        </Grid>
      </Grid>

      <Dialog open={openModal} onClose={handleCloseModal}>
        <PeopleAltTwoToneIcon sx={{ mr: "20px" }} />
        <DialogTitle>Add New Users</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Name"
            fullWidth
            required
            name="name"
            value={newCustomer.name}
            onChange={handleInputChange}
          />
          <span style={{ color: "red" }}>{errorName}</span>
          <TextField
            margin="dense"
            label="Phone"
            fullWidth
            required
            name="phone_number"
            value={newCustomer.phone_number}
            onChange={handleInputChange}
          />
          <span style={{ color: "red" }}>{errorPhone}</span>
          <TextField
            autoFocus
            margin="dense"
            label="Alternate Phone"
            fullWidth
            name="alternate_phone_number"
            value={newCustomer.alternate_phone_number}
            onChange={handleInputChange}
          />
          <span style={{ color: "red" }}>{errorAltPhn}</span>
          <TextField
            autoFocus
            margin="dense"
            label="Email ID"
            fullWidth
            name="email"
            value={newCustomer.email}
            onChange={handleInputChange}
          />
          <span style={{ color: "red" }}>{errorEmail}</span>
          <TextField
            autoFocus
            margin="dense"
            label="Aadhar Number"
            fullWidth
            name="aadhar_number"
            value={newCustomer.aadhar_number}
            onChange={handleInputChange}
          />
          <span style={{ color: "red" }}>{errorAadhar}</span>
          <FormControl fullWidth margin="dense">
            <InputLabel>User Type</InputLabel>
            <Select
              name="user_type"
              value={newCustomer.user_type}
              onChange={handleInputChange}
              required
            >
              <MenuItem value="1">Admin</MenuItem>
              <MenuItem value="2">User</MenuItem>
              <MenuItem value="other">Other</MenuItem>
            </Select>
          </FormControl>
          <span style={{ color: "red" }}>{errorUserType}</span>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal}>Cancel</Button>
          <Button onClick={handleAddCustomer}>Add</Button>
          {/* onClick={handleAddCustomer} */}
        </DialogActions>
      </Dialog>
      {/* Edit popup component */}
      <Dialog open={editOpen} onClose={handleEditClose}>
        <DialogTitle>Edit Users</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            label="Name"
            fullWidth
            required
            name="name"
            value={editedName}
            onChange={(e) => setEditedName(e.target.value)}
          />{" "}
          <TextField
            margin="dense"
            label="Password"
            fullWidth
            required
            name="password"
            value={editedPassword}
            onChange={(e) => setEditedPassword(e.target.value)}
          />
          <TextField
            margin="dense"
            label="Phone Number"
            fullWidth
            required
            name="phone_number"
            value={editedPhoneNumber}
            onChange={(e) => setEditedPhoneNumber(e.target.value)}
          />
          <TextField
            margin="dense"
            label="Alternate Phone"
            fullWidth
            required
            name="alternate_phone_number"
            value={editedAltPhoneNumber}
            onChange={(e) => setEditedAltPhoneNumber(e.target.value)}
          />
          <TextField
            margin="dense"
            label="Email"
            fullWidth
            required
            name="email"
            value={editedEmail}
            onChange={(e) => setEditedEmail(e.target.value)}
          />
          <TextField
            margin="dense"
            label="Aadhar Number"
            fullWidth
            required
            name="aadhar_number"
            value={editedAadhar}
            onChange={(e) => setEditedAadhar(e.target.value)}
          />
          <Select
            margin="dense"
            label="User Type"
            fullWidth
            required
            name="user_type"
            value={editedUserType}
            onChange={(e) => setEditedUserType(e.target.value)}
          >
            <MenuItem value="1">Admin</MenuItem>
            <MenuItem value="2">User</MenuItem>
            <MenuItem value="other">Other</MenuItem>
          </Select>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEditClose}>Cancel</Button>
          <Button onClick={handleEditSubmit}>Save</Button>
        </DialogActions>
      </Dialog>
      <DataGrid
        //rows={rows}
        rows={rows.map((row, index) => ({ ...row, slno: index + 1 }))}
        columns={columns}
        getRowClassName={getRowClassName} // Apply row styling
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 5,
            },
          },
        }}
        pageSizeOptions={[5]}
        getRowId={(row) => row.user_id}
      />
      {/* Display user details */}
    </Box>
  );
}
