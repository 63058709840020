import * as React from "react";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import "../../index.css";
import { useEffect, useState } from "react";
import axios from "axios";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Select, // Added Select component
  MenuItem, // Added MenuItem component
  FormControl, // Added FormControl component
  InputLabel, // Added InputLabel component
  useTheme,
  Grid,
} from "@mui/material";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import PeopleAltTwoToneIcon from "@mui/icons-material/PeopleAltTwoTone";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import DeleteIcon from "@mui/icons-material/Delete";
import * as XLSX from "xlsx";
import { editOptionAlert } from "../support/sweetAlert";
import { handleCustomerDelete } from "../support/deleteData";
import { insertedBy } from "../support/sessionUserName";
import { baseURL } from "../support/baseURL";

export default function CustomerTable() {
  const [rows, setRows] = useState([]);
  const [openModal, setOpenModal] = useState(false);

  const [editOpen, setEditOpen] = useState(false);
  const [editedRow, setEditedRow] = useState(null);
  const [editedShopName, setEditedShopName] = useState("");
  const [editedPersonName, setEditedPersonName] = useState("");
  const [editedPhoneNumber, setEditedPhoneNumber] = useState("");
  const [editedAltPhoneNumber, setEditedAltPhoneNumber] = useState("");
  const [editedEmail, setEditedEmail] = useState("");
  const [editedType, setEditedType] = useState("");
  const [editedPurity, setEditedPurity] = useState("");
  const [editedGSTNumber, setEditedGSTNumber] = useState("");
  const [editedShopAddress, setEditedShopAddress] = useState("");

  const [errorShopName, setErrorShopName] = useState("");
  const [errorPerson, setErrorPersonName] = useState("");
  const [errorPhoneNumber, setErrorPhoneNumber] = useState("");
  const [errorAltPhoneNumber, setErrorAltPhoneNumber] = useState("");
  const [errorEmail, setErrorEmail] = useState("");
  const [errorType, setErrorType] = useState("");
  const [errorGSTNumber, setErrorGSTNumber] = useState("");
  const [errorShopAddress, setErrorShopAddress] = useState("");

  const [ setUserDetails] = useState(null);

  const [customerOrderedData, setCustomerOrderedData] = useState([]);

  const [selectedPurity, setSelectedPurity] = useState("");

  const [newCustomer, setNewCustomer] = useState({
    shop_name: "",
    contact_person_name: "",
    phone_number: "",
    alternate_phone_number: "",
    email: "",
    type: "",
    gst_number: "",
    shop_address: "",
    purity: "",
    inserted_by: insertedBy(),
  });

  const theme = useTheme();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewCustomer({ ...newCustomer, [name]: value });
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleEditOpen = (row) => {
    if (sessionStorage.getItem("userType") == "1") {
      setEditedRow(row);
      setEditedShopName(row.shop_name);
      setEditedPersonName(row.contact_person_name);
      setEditedPhoneNumber(row.phone_number);
      setEditedAltPhoneNumber(row.alternate_phone_number);
      setEditedEmail(row.email);
      setEditedType(row.type);
      setEditedPurity(row.purity);
      setEditedGSTNumber(row.gst_number);
      setEditedShopAddress(row.shop_address);
      setEditOpen(true);
    } else {
      if (row.inserted_by == insertedBy()) {
        setEditedRow(row);
        setEditedShopName(row.shop_name);
        setEditedPersonName(row.contact_person_name);
        setEditedPhoneNumber(row.phone_number);
        setEditedAltPhoneNumber(row.alternate_phone_number);
        setEditedEmail(row.email);
        setEditedType(row.type);
        setEditedPurity(row.purity);
        setEditedGSTNumber(row.gst_number);
        setEditedShopAddress(row.shop_address);
        setEditOpen(true);
      } else {
        /** No Edit Option  */
        editOptionAlert();
      }
    }
  };

  const handleEditClose = () => {
    setEditOpen(false);
  };

  const handleAddCustomer = async () => {
    // Reset error messages
    setErrorShopName("");
    setErrorPersonName("");
    setErrorPhoneNumber("");
    setErrorAltPhoneNumber("");
    setErrorEmail("");
    setErrorType("");
    setErrorGSTNumber("");
    setErrorShopAddress("");

    // Check for empty fields
    if (!newCustomer.contact_person_name || !newCustomer.phone_number) {
      if (!newCustomer.contact_person_name)
        setErrorPersonName("Please Enter The Person Name");
      if (!newCustomer.phone_number)
        setErrorPhoneNumber("Please Enter The Phone Number");
      if (!newCustomer.type) setErrorType("Please Enter The Type");
      return; // Stop execution if any field is empty
    }

    // Sanitize phone numbers
    const sanitizePhoneNumber = (phoneNumber) => {
      return phoneNumber.replace(/\D/g, "");
    };

    const sanitizedPhone = sanitizePhoneNumber(newCustomer.phone_number);

    // Check if phone numbers consist of exactly 10 digits
    if (sanitizedPhone.length !== 10) {
      setErrorPhoneNumber("Phone number must be 10 digits");
      return; // Stop execution if phone number is invalid
    }

    try {
      // Perform API call to add customer
      const addResponse = await axios.post(
        `${baseURL}/customers/addCustomer`,
        newCustomer
      );

      if (addResponse.data.mStatus === 400) {
        alert("There is a Duplicate Data, Please Enter The Unique Values");
      } else if (addResponse.data.mStatus === 200) {
        alert("Customer Data Added Successfully");
        fetchedData();
        // Reset newCustomer object after successful addition
        setNewCustomer({
          shop_name: "",
          contact_person_name: "",
          phone_number: "",
          alternate_phone_number: "",
          email: "",
          type: "",
          gst_number: "",
          shop_address: "",
          purity: "",
          inserted_by: insertedBy(),
        });
        handleCloseModal();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getRowClassName = (params) => {
    return "customRow"; // Return the class name for row styling
  };

  useEffect(() => {
    fetchedData();
  }, []);

  const fetchedData = async () => {
    try {
      const response = await axios.get(`${baseURL}/customers/getCustomer`);
      if (response.data.mData) {
        const filteredRows = response.data.mData.filter(
          (row) => row.is_delete === 0
        );
        setRows(filteredRows);
        localStorage.setItem("lenOfCust", filteredRows.length);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handlePurityFilter = (event) => {
    const { value } = event.target;
    setSelectedPurity(value);
  };

  const filteredRows = selectedPurity
    ? rows.filter((row) => row.purity === selectedPurity)
    : rows;

  const handleNameClick = (rowData) => {
    setUserDetails(rowData);
  };


  const handleEditSubmit = async () => {
    try {
      // Construct the updated order object including order_id
      const updatedOrder = {
        customer_id: editedRow.customer_id,
        shop_name: editedShopName,
        contact_person_name: editedPersonName,
        phone_number: editedPhoneNumber,
        alternate_phone_number: editedAltPhoneNumber,
        email: editedEmail,
        type: editedType,
        purity: editedPurity,
        gst_number: editedGSTNumber,
        shop_address: editedShopAddress,
      };

      // Update the row with the edited data
      const updatedRows = rows.map((row) => {
        if (row.customer_id === editedRow.customer_id) {
          return {
            ...row,
            shop_name: editedShopName,
            contact_person_name: editedPersonName,
            phone_number: editedPhoneNumber,
            alternate_phone_number: editedAltPhoneNumber,
            email: editedEmail,
            type: editedType,
            purity: editedPurity,
            gst_number: editedGSTNumber,
            shop_address: editedShopAddress,
          };
        }
        return row;
      });
      setRows(updatedRows);

      // Send updated data to API
      const response = await axios.post(
        `${baseURL}/customers/updateCustomer`,
        updatedOrder
      );

      // Handle success or failure
      if (response.data.mStatus === 200) {
        alert("Customer updated successfully");
        fetchedData();
      } else {
        alert("Failed to update order");
      }

      handleEditClose();
    } catch (error) {
      console.error("Error updating order:", error);
    }
  };

  const columns = [
    {
      field: "slno",
      headerName: "Sl. No",
      width: 80,
      headerClassName: "customHeader",
      renderCell: (params) => {
        return params.row.slno;
      },
    },
    {
      field: "customer_id",
      headerName: "Customer ID",
      width: 110,
      headerClassName: "customHeader",
    },
    {
      field: "contact_person_name",
      headerName: "Name",
      width: 170,
      headerClassName: "customHeader",
      cellClassName: "customCell",
      renderCell: (params) => {
        const textColor = theme.palette.mode === "dark" ? "#FFFFFF" : "#000000"; // White in dark mode, black in light mode
        return (
          <Button
            onClick={() => handleNameClick(params.row)}
            variant="text"
            color="primary"
            style={{ color: textColor }}
          >
            {params.value}
          </Button>
        );
      },
    },
    {
      field: "purity",
      headerName: "Group",
      width: 150,
      headerClassName: "customHeader",
      cellClassName: "customCell",
    },
    {
      field: "phone_number",
      headerName: "Phone",
      width: 150,
      headerClassName: "customHeader",
      cellClassName: "customCell",
    },
    {
      field: "email",
      headerName: "Email",
      width: 250,
      headerClassName: "customHeader",
      cellClassName: "customCell",
    },
    {
      field: "shop_address",
      headerName: "Shop Address",
      width: 250,
      headerClassName: "customHeader",
      cellClassName: "customCell",
    },
    {
      field: "purity",
      headerName: "Group",
      width: 150,
      headerClassName: "customHeader",
      cellClassName: "customCell",
    },
    {
      field: "Action",
      headerName: "Action",
      // width: 200,
      headerClassName: "customHeader",
      cellClassName: "customCell",
      renderCell: (params) => {
        return (
          <div>
            {/* Replace these with your desired icons */}
            <BorderColorIcon
              style={{ cursor: "pointer", marginRight: "5px" }}
              onClick={() => handleEditOpen(params.row)}
            />
            <DeleteIcon
              style={{ cursor: "pointer", marginRight: "5px" }}
              onClick={() =>
                handleCustomerDelete(rows, params.row.customer_id, fetchedData)
              }
            />
          </div>
        );
      },
    },
  ];

  const CustomersOrder = async ({ customerId }) => {
    try {
      const customerOrderedData = await axios.post(
        `${baseURL}/customers/getCustomerMappedOrders`,
        {
          customer_id: customerId,
        }
      );
      if (customerOrderedData.data.mStatus === 200) {
        setCustomerOrderedData(customerOrderedData.data.mData);
      }
    } catch (error) {
      console.error(error);
    }
    return (
      <box>
        {customerOrderedData.map((items) => {
          <div>
            <h1>{items.order_id}</h1>
            <h1>{items.description}</h1>
          </div>;
        })}
      </box>
    );
  };

  const handleExportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(rows);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Customers");
    XLSX.writeFile(workbook, "customers.xlsx");
  };

  return (
    <Box sx={{ height: 500, width: "97%", marginX: "2%" }}>
      {/* Details popup component */}

      {/* <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Button
          onClick={handleOpenModal}
          startIcon={<PeopleAltTwoToneIcon />}
          variant="contained"
          sx={{ mt: 2 }}
        >
          Add Customer
        </Button>
        <Button
          variant="contained"
          color="secondary"
          sx={{ height: "50px" }}
          startIcon={<DownloadOutlinedIcon />}
          onClick={handleExportToExcel}
        >
          Export to Excel
        </Button>
        <FormControl margin="dense">
          <InputLabel id="demo-simple-select-label">Group</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            value={selectedPurity}
            onChange={handlePurityFilter}
            sx={{ width: 200, marginTop: "-2%" }}
          >
            <MenuItem value="">All</MenuItem>
            <MenuItem value="SKV/5">SKV/5</MenuItem>
            <MenuItem value="SKV?80">SKV?80</MenuItem>
            <MenuItem value="GST">GST</MenuItem>
          </Select>
        </FormControl>
        <Button
          // onClick={fetchedData}
          sx={{
            backgroundColor: colors.blueAccent[700],
            color: colors.grey[100],
            fontSize: "14px",
            fontWeight: "bold",
            padding: "10px 20px",
            height: "50px",
          }}
        >
          <RestoreTwoToneIcon sx={{ mr: "10px" }} />
          Refresh
        </Button>
      </div> */}

      <Grid
        container
        spacing={2}
        alignItems="center"
        justifyContent="space-between"
        sx={{ mb: 2 }}
      >
        <Grid item xs={12} sm={6} md={3}>
          <Button
            fullWidth
            onClick={handleOpenModal}
            startIcon={<PeopleAltTwoToneIcon />}
            variant="contained"
            sx={{ height: "50px" }}
          >
            Add Customer
          </Button>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Button
            fullWidth
            variant="contained"
            color="secondary"
            sx={{ height: "50px" }}
            startIcon={<DownloadOutlinedIcon />}
            onClick={handleExportToExcel}
          >
            Export to Excel
          </Button>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <FormControl fullWidth margin="dense">
            <InputLabel id="group-select-label">Group</InputLabel>
            <Select
              labelId="group-select-label"
              value={selectedPurity}
              onChange={handlePurityFilter}
              sx={{ height: "50px" }}
            >
              <MenuItem value="">All</MenuItem>
              <MenuItem value="SKV/5">SKV/5</MenuItem>
              <MenuItem value="SKV?80">SKV?80</MenuItem>
              <MenuItem value="GST">GST</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      {/* Add popup component */}
      <Dialog open={openModal} onClose={handleCloseModal}>
        <PeopleAltTwoToneIcon sx={{ mr: "20px" }} />
        <DialogTitle>Add New Customer</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Shop Name"
            fullWidth
            name="shop_name"
            value={newCustomer.shop_name}
            onChange={handleInputChange}
          />
          {/* <p style={{ color: "red" }}>{errorName}</p> */}
          <span style={{ color: "red" }}>{errorShopName}</span>
          <TextField
            margin="dense"
            label="Name"
            fullWidth
            required
            name="contact_person_name"
            value={newCustomer.contact_person_name}
            onChange={handleInputChange}
          />
          <span style={{ color: "red" }}>{errorPerson}</span>
          <TextField
            autoFocus
            margin="dense"
            label="Phone Number"
            fullWidth
            required
            name="phone_number"
            value={newCustomer.phone_number}
            onChange={handleInputChange}
          />
          <span style={{ color: "red" }}>{errorPhoneNumber}</span>
          <TextField
            autoFocus
            margin="dense"
            label="Alternate Phone Number"
            fullWidth
            name="alternate_phone_number"
            value={newCustomer.alternate_phone_number}
            onChange={handleInputChange}
          />
          <span style={{ color: "red" }}>{errorAltPhoneNumber}</span>
          <TextField
            autoFocus
            margin="dense"
            label="Email Id"
            fullWidth
            name="email"
            value={newCustomer.email}
            onChange={handleInputChange}
          />
          <span style={{ color: "red" }}>{errorEmail}</span>
          <FormControl fullWidth margin="dense">
            <InputLabel>Group</InputLabel>
            <Select
              name="purity"
              value={newCustomer.purity}
              onChange={handleInputChange}
              required
            >
              <MenuItem value="SKV/5">SKV/5</MenuItem>
              <MenuItem value="SKV?80">SKV?80</MenuItem>
              <MenuItem value="GST">GST</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth margin="dense">
            <InputLabel>Type</InputLabel>
            <Select
              name="type"
              value={newCustomer.type}
              onChange={handleInputChange}
              required
            >
              <MenuItem value="retailer">Retailer</MenuItem>
              <MenuItem value="wholesaler">WholeSaler</MenuItem>
              <MenuItem value="other">Other</MenuItem>
            </Select>
          </FormControl>
          <span style={{ color: "red" }}>{errorType}</span>
          <TextField
            margin="dense"
            label="GST Number"
            fullWidth
            name="gst_number"
            value={newCustomer.gst_number}
            onChange={handleInputChange}
          />
          <span style={{ color: "red" }}>{errorGSTNumber}</span>
          <TextField
            margin="dense"
            label="Shop Address"
            fullWidth
            multiline="true"
            name="shop_address"
            value={newCustomer.shop_address}
            onChange={handleInputChange}
          />
          <span style={{ color: "red" }}>{errorShopAddress}</span>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal}>Cancel</Button>
          <Button onClick={handleAddCustomer}>Add</Button>
          {/* onClick={handleAddCustomer} */}
        </DialogActions>
      </Dialog>

      {/* Edit popup component */}
      <Dialog open={editOpen} onClose={handleEditClose}>
        <DialogTitle>Edit Customer</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            label="Shop Name"
            fullWidth
            required
            name="shop_name"
            value={editedShopName}
            onChange={(e) => setEditedShopName(e.target.value)}
          />
          <TextField
            margin="dense"
            label="Person Name"
            fullWidth
            required
            name="contact_person_name"
            value={editedPersonName}
            onChange={(e) => setEditedPersonName(e.target.value)}
          />
          <TextField
            margin="dense"
            label="Phone Number"
            fullWidth
            required
            name="phone_number"
            value={editedPhoneNumber}
            onChange={(e) => setEditedPhoneNumber(e.target.value)}
          />
          <TextField
            margin="dense"
            label="Alternate Phone"
            fullWidth
            required
            name="alternate_phone_number"
            value={editedAltPhoneNumber}
            onChange={(e) => setEditedAltPhoneNumber(e.target.value)}
          />
          <TextField
            margin="dense"
            label="Email"
            fullWidth
            required
            name="email"
            value={editedEmail}
            onChange={(e) => setEditedEmail(e.target.value)}
          />
          <FormControl fullWidth margin="dense">
            <InputLabel>Group</InputLabel>
            <Select
              name="purity"
              value={editedPurity}
              onChange={(e) => setEditedPurity(e.target.value)}
              required
            >
              <MenuItem value="SKV/5">SKV/5</MenuItem>
              <MenuItem value="SKV?80">SKV?80</MenuItem>
              <MenuItem value="GST">GST</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth margin="dense">
            <InputLabel>Type</InputLabel>
            <Select
              name="type"
              value={editedType}
              onChange={(e) => setEditedType(e.target.value)}
              required
            >
              <MenuItem value="retailer">Retailer</MenuItem>
              <MenuItem value="wholesaler">WholeSaler</MenuItem>
              <MenuItem value="other">Other</MenuItem>
            </Select>
          </FormControl>
          <TextField
            margin="dense"
            label="GST Number"
            fullWidth
            required
            name="gst_number"
            value={editedGSTNumber}
            onChange={(e) => setEditedGSTNumber(e.target.value)}
          />
          <TextField
            margin="dense"
            label="Shop Address"
            fullWidth
            required
            multiline="true"
            name="shop_address"
            value={editedShopAddress}
            onChange={(e) => setEditedShopAddress(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEditClose}>Cancel</Button>
          <Button onClick={handleEditSubmit}>Save</Button>
        </DialogActions>
      </Dialog>

      <DataGrid
        //rows={rows}
        rows={filteredRows.map((row, index) => ({ ...row, slno: index + 1 }))}
        columns={columns}
        getRowClassName={getRowClassName} // Apply row styling
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 5,
            },
          },
        }}
        pageSizeOptions={[5]}
        getRowId={(row) => row.customer_id}
      />
    </Box>
  );
}
